import React from 'react';
import Tabcontainer from '../../Tabcontainer/Tabcontainer';
import './DoctorHome.scss';
import { useState, useEffect } from 'react';
import { useAuth } from '../../../services/use-auth';
import Resource from '../../../services/resource.service';
import Toast from '../../Toaster/Toast';
import PatientHomeTab from './PatientQMS/PatientHomeTab/PatientHomeTab';
import useHandleEnter from '../../../hooks/useHandleEnter';
import { useNavigate } from 'react-router-dom';
import {QRCode} from 'react-qrcode-logo';


import mlogo from '../../../images/mlogo.png';

const DoctorHome = () => {

    const auth = useAuth();
    const navigate = useNavigate();
    const [patientData, setPatientData] = useState([]);
    const [showPatientForm, setShowPatientForm] = useState(false);
    const [reload, setReload] = useState(false);
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [activeTab, setActivetab] = useState("Registration Desk");
    const [disableRegisterButton, setDisableRegisterButton] = useState(true);
    const [displayTimer, setDisplayTimer] = useState(false);
    const [clinicId, setClinicId] = useState(0);
    const [clinics, setClinics] = useState( new Map());
    const [counter, setCounter] = useState(5);
    const [drs, setDrs] = useState( new Array() );
    const [doctorId, setDoctorId] = useState(0);
    const [newDate, setNewDate] = useState();
    const {handleKeyDown} = useHandleEnter();
    const [isSendSMS, setIsSendSMS] = useState(false);
    const [time, setTime] = useState(Date.now());

    let tabLabels = [
        'Registration Desk','Patient Queue'
    ];

    /*const qms_list = [
        {
            clinic_qms_id: 1,
            token_number: 1,
            patient_name: 'Gupta',
            patient_phone: '1234567890',
            doctor_name: 'Dr.A.K.Singh',
            patient_age: '44',
            patient_gender: 'M',
            date: '2023-02-22',
            //patient_health_condition: 'Diabetes',
            consultation_fee: '500',
            //consultation_payment_method: 'CASH'
        },
        {
            clinic_qms_id: 2,
            token_number: 2,
            patient_name: 'Archana',
            patient_phone: '9876543210',
            doctor_name: 'Dr.Seema',
            patient_age: '30',
            patient_gender: 'F',
            date: '2023-02-23',
            //patient_health_condition: 'PCOS',
            consultation_fee: '1500',
            //consultation_payment_method: 'UPI'
        },
        {
            clinic_qms_id: 3,
            token_number: 3,
            patient_name: 'Disha',
            patient_phone: '9983564510',
            doctor_name: 'Dr.Riya',
            patient_age: '25',
            patient_gender: 'F',
            date: '2023-03-21',
            //patient_health_condition: 'PCOS',
            consultation_fee: '700',
            //consultation_payment_method: 'UPI'
        },
        {
            clinic_qms_id: 4,
            token_number: 4,
            patient_name: 'Bala',
            patient_phone: '8923458510',
            doctor_name: 'Dr.Akshaya',
            patient_age: '38',
            patient_gender: 'M',
            date: '2023-03-13',
            //patient_health_condition: 'PCOS',
            consultation_fee: '1200',
            //consultation_payment_method: 'UPI'
        }
    ]*/

    function suggestionLookup(query) {
        var otype = 'patient;lead';

        var params = {
            q: query,
            otype: otype
        }

        return Resource.autocomplete(params);
    }

    function callbackLookup(item) {
        if(item) {
            Resource.getUserById(item.type, item.id).then((resposne) => {
                if(resposne.success) {
                    setPatientData([resposne.data]);
                }
                else {
                    Toast.error("ERROR: User not found");
                }
            });
        }
        else {
            setReload(!reload);
        }
    }

    const toggleSendSMS = () => {
        setIsSendSMS(!isSendSMS);
    }

    const showAddPatientForm = () => {
        setShowPatientForm(true);
    }

    const closeModal = () => {
        setShowPatientForm(false);
    }

    const onPatientAddCallback = (response) => {
        closeModal();
        if(response.success && response.data.lead_id) {
            navigate('/lead/' + response.data.lead_id);
        }
    }

    const setDimension = () => {
        setScreenSize(window.innerWidth);
    }

    const validatePhoneNumber = () => {
        let phonevalueError = "Please provide a 10 digit valid phone number";
        let phoneformat = /^([6-9])(\d{9})$/;
        let phoneNumber = document.getElementById('phoneNumber');
        let phoneerror = document.getElementById('phoneerror');
        if(phoneNumber.value.length > 10 || phoneNumber.value.length < 10 && !phoneNumber.value.match(phoneformat)) {
           setDisableRegisterButton(true);
           phoneerror.style.display = "inline";
           phoneNumber.classList.add('invalid');
           phoneerror.innerHTML = phonevalueError;
        }
        else {
           setDisableRegisterButton(false);
           phoneNumber.classList.remove('invalid');
           phoneerror.style.display = "none";
        }
    }

    const validateDoctorField = () => {
        let doctorerror = document.getElementById('drNameerror');
        let doctorName = document.getElementById('p-drName');    
        if(drs.length > 1 && !doctorId) {
            doctorerror.style.display = "inline";
            doctorName.classList.add('invalid');
            doctorerror.innerHTML = 'Please select the doctor';
            return false;
        } 
        else {
            if(doctorName) {
                doctorName.classList.remove('invalid');
                doctorerror.style.display = "none";
            }
            return true;
        }
    }

    const setActiveTab = (value) => {
        let Modulestab = tabLabels[value];
        setActivetab(Modulestab);
    }

    const registerPatient = () => {
        let payload = {};
        
        if(isSendSMS)
            payload['send_sms'] = true;

        if(clinicId)
            payload['clinic_id'] = clinicId;

        if(doctorId)
            payload['doctor_id'] = parseInt(doctorId);

        let phoneNumber = document.getElementById('phoneNumber');

        if(phoneNumber.value)
            payload['phone_number'] = phoneNumber.value;

        if( validateDoctorField() ) {
            Resource.queueRegister(payload).then((response) => {
                if(response.success) {
                    setDisplayTimer(true);   
                    setDisableRegisterButton(false);
                }
                else {
                    Toast.error('ERROR: Please try again');
                }
            });
        }
    }

    const sendDate = (newDate) => {
        console.log(newDate);
        
        setNewDate(newDate)
    }

    useEffect(() => {

        if(activeTab == 'Patient Queue') {
            let filter = {};
            if(clinicId) {
                filter = {
                    'clinic_id': clinicId,
                    'date': newDate ? newDate : new Date().toISOString().split('T')[0],
                    'desc': true
                }
            }

            Resource.getUsers('visitor', auth.user.id, filter).then((response) => {
                if(response.success) {
                    setPatientData(response.data);
                }
            });
        }

    }, [reload, activeTab, auth.user, newDate, time]);

    /** Refresh patient list every 30 seconds **/
    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), 30000);
        return () => {
            clearInterval(interval);
        };
    }, []);
    /** **/

    useEffect(() => {
        window.addEventListener('resize', setDimension);
        return(() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize]);

    useEffect(() => {
        if( auth.user && auth.user.clinics && auth.user.clinics.length > 0 ) {
            let cl = new Map();
            for(let i = 0; i < auth.user.clinics.length; i++) {
                if (!cl.has(auth.user.clinics[i].id)) {
                    cl[ auth.user.clinics[i].id ] = auth.user.clinics[i];
                }
            }
            setClinics(cl);
            if(Object.keys(cl).length == 1)
                setClinicId( auth.user.clinics[0].id );
        }
        
    }, []);

    useEffect(() => {

        if(clinicId) {
            Resource.getUsers(Resource.ROLES.DOCTOR, auth.user.id, {clinic_id: clinicId}).then((response) => {
                if(response.success) {
                    console.log(response.data);
                    setDrs(response.data);
                }
            });
        }
        
        
    }, [clinicId]);

    return(
        <div className='doctorHome'>
            <div className="doctorHomeTab">
                <Tabcontainer className={'centerTab'} setActiveTab={setActiveTab} tabs={tabLabels}/>
            </div>
            {
                activeTab === "Registration Desk" ?
                <div className='registrationDesk'>
                    <div className='registerForm registrationForm'>
                        <div>
                            <h2>{clinicId ? clinics[clinicId].name: ''}</h2><br/>
                        </div>
                        { 
                        clinics && Object.keys(clinics).length == 1 ? '' :
                        <div className='form-element'>
                            <label>Clinic Name</label>
                            <select disabled={Object.keys(clinics).length == 1 ? true : false} id="p-clinicName" value={clinicId} onChange={(e)=>setClinicId(e.target.value)} className="inputField" placeholder='Select Clinic' required>
                                {Object.keys(clinics).length > 1 ? <option value="">-- Select Clinic --</option> : ''}
                                {
                                    clinics && Object.keys(clinics).map(clinic_id => {
                                        let clinic = clinics[clinic_id];
                                        return <option key={clinic_id} value={clinic_id}>{clinic.name}</option>
                                    })
                                
                                }
                            </select>
                            <span className="field-error" id="clinicNameerror"></span>
                        </div>
                        }

                        {/*
                            drs && drs.length <= 1 ? '' :
                            <div className='form-element'>
                            <label>Doctor Name</label>
                            <select disabled={drs.length == 1 ? true : false} id="p-drName" value={doctorId} onChange={(e)=>{ setDoctorId(e.target.value); } } className="inputField" placeholder='Select Doctor' required>
                                {drs.length > 1 ? <option value="">-- Select Doctor --</option> : ''}
                                {
                                    drs && drs.map(dr => {
                                        return <option key={dr.doctor_id} value={dr.doctor_id}>{dr.first_name + ' ' + dr.last_name}</option>
                                    })
                                
                                }
                            </select>
                            <span className="field-error" id="drNameerror"></span>
                        </div>
                        */}
                        {
                            clinics[clinicId] &&
                            <div className='clinicQRCode'>
                                <QRCode 
                                    value={Resource.PATIENT_QR_HOST + '/register-patient-' +  clinics[clinicId].code} 
                                    size={200} 
                                    fgColor='#fd6483'
                                    eyeColor='#007050'
                                    logoImage={mlogo}
                                    removeQrCodeBehindLogo={true}
                                    qrStyle='dots'
                                    logoPaddingStyle='circle'
                                    eyeRadius={5}
                                    ></QRCode>
                            </div>
                        }
                        {
                        /*
                        <div className='form-element'>
                            <label>10-digit Whatsapp Number</label>
                            <input type="tel" id="phoneNumber" className="inputField" placeholder="Enter phone number" onChange={validatePhoneNumber} onKeyDown={(e) => handleKeyDown(e,registerPatient)} required />
                            <span className="field-error" id="phoneerror"></span>
                        </div>
                        <div className='form-element checkbox'>
                            <input
                                id='sendSMS'
                                type="checkbox"
                                checked={isSendSMS}
                                onChange={toggleSendSMS}/>
                            <label for='sendSMS' >
                                Send SMS (If WhatsApp Not Available)
                            </label>
                        </div>
                        
                        
                        <Button text={'Register'} disable={disableRegisterButton} onclick={registerPatient} buttonType={'primary_btn'} />*/
                        }
                    </div>
                    
                </div>
                :
                <PatientHomeTab patientQmsData={patientData} tabs={tabLabels} suggestionLookup={suggestionLookup} callbackLookup={callbackLookup} sendDate={sendDate} />

            } 
        </div>
    )
}

export default DoctorHome;