import React, { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import './PrognosisReport.scss';
import Button from '../Button/Button';
import BusyLoading from '../../helpers/loading';

const PrognosisReport = ({htmlData, fetchHtmlData, view}) => {
  const [html, setHtml] = useState('');

  const [reportView, setReportView] = useState(false);

  const componentRef = useRef();

  const clearReport = () =>  {
    componentRef.current.innerHTML = ''; 
    setHtml('');
    setReportView(false);
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      clearReport(); 
      BusyLoading.hide();
    }
  });

  useEffect(() => {
    if(html) {
      if(!view) {
        handlePrint();
      }
      else {
        componentRef.current.classList.add('viewable');
        setReportView(true);
      }
    }
  }, [html])

  return (
    <>
      <Button onclick={(e) => {
        e.stopPropagation();
        if(!htmlData && fetchHtmlData) {
          fetchHtmlData().then((resp)=>{
            componentRef.current.innerHTML = resp.data;
            setHtml(resp.data);
          });
        }
      }}>
        {
        view ? 
        'VIEW' : 'PRINT'
      
      }</Button>
      <html className='htmlContent' ref={componentRef}></html>
      {
        reportView &&
        <div className='printReportButtonWrap'>
        <Button className='printReportButton btn-primary' onclick={(e) => {
          BusyLoading.show();
          componentRef.current.classList.remove('viewable');
          handlePrint();
        }}>
          PRINT
        </Button>
        <Button className='cancelReportButton' onclick={(e) => {
          componentRef.current.classList.remove('viewable');
          clearReport();
        }}>
          CLOSE
        </Button>
        </div>
      }
    </>
  )
}

export default PrognosisReport;